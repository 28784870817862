<script>
  import { createEventDispatcher } from "svelte";
  import FormValidate from "./FormValidate.svelte";
  import Closer from "./base/Closer.svelte";
  import TelField from "./base/TelField.svelte";
  import Button from "./base/Button.svelte";
  import { toArray, getWrittenName, isIPhone } from "../utils/utilities";
  import Spinner from "./base/Spinner.svelte";
  import { fileUpload } from "../utils/sendwhatsapp_rewrite_chabi";
  import { isClosedConversationStore } from "../store";
  import Toast from "./base/Toast.svelte";

  const dispatch = createEventDispatcher();

  let errorList = [];
  let formValidateList = [];
  let showBooking = false;
  let isLoading = false;
  let cookieModalExist = eval("typeof modalPrivacityTermsCookies") === "function";

  export let formitems;
  export let data;
  export let noclose = false;
  export let person;
  export let isBigperson = false;

  $: fullScreenMobile = data.expandFullScreenMobile;
  $: notQualified = false;
  $: isSent = false;
  $: sendMessage = data.transSuccessMessage ?? "Your message has been sent";

  let isClosedConversation = false;

  let filtered = formitems.items.map((item) => item.fieldToFilterFrom);
  formitems.items.filter((item) => item.type !== "freetext").forEach((a) => (a.val = ""));

  if (person?.bookingConfigs?.length > 0 && isBigperson) {
    showBooking = true;
  }

  const handleFileSelected = (e, i) => {
    formitems.items[i].val = e.target.files[0];
  };

  const sendtocallme = async (url = null) => {
    if (isLoading) {
      return;
    }
    if (showBooking) {
      dispatch("booking");
      return;
    }
    for (let i in formValidateList)
      try {
        formValidateList[i].validate();
      } catch (ex) {
        //console.log(ex);
      }
    if (errorList.length == 0) {
      for (let i in formitems.items) {
        if (formitems.items[i].type === "file" && formitems.items[i].val && !filtered[i]) {
          isLoading = true;
          const res = await fileUpload(formitems.items[i].val, person.id);
          formitems.items[i].val = res?.data?.id;
        }
        if (formitems.items[i].type === "hidden") {
          formitems.items[i].val = formitems.items[i].value ?? formitems.items[i].hval;
        }
      }
      isLoading = false;
      let scoreCnt = 0,
        scoreSum = 0,
        score;
      if (person.form.activateScoringFilter) {
        for (let item of formitems.items) {
          if (item.type === "select") {
            score = item.dataObject.find((obj) => obj.value === item.val)?.score;
            switch (score) {
              case "gold":
                scoreSum += 3;
                break;
              case "silver":
                scoreSum += 2;
                break;
              case "bronze":
                scoreSum += 1;
                break;
            }
            scoreCnt++;
          }
        }
      }
      notQualified =
        (scoreCnt > 0 && scoreSum / scoreCnt < 2) ||
        (person.online === false && data.offlineLeads === true) ||
        (data.desktopLeads === true && window.innerWidth > 1000);
      dispatch("callmex", {
        data: formitems.items.filter((item, i) => item.type !== "freetext" && !filtered[i]),
        url: url,
        notQualified: notQualified,
      });

      isSent = true;

      setTimeout(() => {
        isSent = false;
      }, 3500);

      clearForm();
    }
  };

  const handleKeyPress = (event) => {
    if (event.code == "Enter") {
      event.preventDefault();
    }
  };

  const handleBlur = (event, index) => {
    if (isClosedConversation) {
      return;
    }
    if (event.code == "Enter") {
      event.preventDefault();
    }
    formitems.items.forEach((item, i) => {
      checkFilter(i);
    });
    if (formitems.items[index].type === "select") {
      const option = formitems.items[index].dataObject.find((obj) => obj.value === formitems.items[index].val);
      if (option.closeConversation) {
        sendMessage = option.closeConversationMessage;
        isClosedConversationStore.set(true);
        isClosedConversation = true;
        dispatch("callmex", {
          data: formitems.items.filter((item, i) => item.type !== "freetext" && !filtered[i]),
          notQualified: true,
        });
        isSent = true;
      }
    }
  };

  let isDateInput = Array(person.form.items.length).fill(false);
  let isTimeInput = Array(person.form.items.length).fill(false);

  function onDateFocus(index) {
    isDateInput[index] = true;
  }

  function onDateBlur(index) {
    isDateInput[index] = false;
  }

  function onTimeFocus(index) {
    isTimeInput[index] = true;
  }

  function onTimeBlur(index) {
    isTimeInput[index] = false;
  }

  const checkFilter = (index) => {
    if (index < 0) {
      return false;
    }
    const item = formitems.items[index];
    if (!item.fieldToFilterFrom) {
      return false;
    }
    const values = item.valueToFilterFrom.split("|");

    const checkItem = formitems.items[item.fieldToFilterFrom - 1];
    let isMatch = true;
    if (filtered[item.fieldToFilterFrom - 1] !== true) {
      values.forEach((value) => {
        if (checkItem.type === "checkbox") {
          if (checkItem.val === (value === 1 || value === "1")) {
            isMatch = false;
          }
        } else if (value.trim() === checkItem.val.trim()) {
          isMatch = false;
        }
      });
    }

    filtered[index] = isMatch;
    return isMatch;
  };

  const clearForm = () => {
    formitems.items.forEach((item) => {
      item.val = "";
    });
  };

  function selectOption(data, item, element) {
    item.val = data;
    element.parentElement.childNodes.forEach((el) => {
      el.classList.remove("selected");
    });
    element.classList.add("selected");

    formitems.items.forEach((_, i) => {
      checkFilter(i);
    });
  }
</script>

<div class:fullScreenMobile class="chatwith-form-wrapper">
  <div class="chatwith-form">
    <div style="padding-top: 20px">
      {#if noclose !== true}
        <Closer on:close={() => dispatch("close")} />
      {/if}
      {#if !showBooking}
        {#if formitems.info != ""}
          <div class="textito">{formitems.info}</div>
        {/if}
        {#each formitems.items as item, i}
          <div class:hidden={filtered[i]}>
            {#if item.type == "text"}
              <div class="form-element">
                <input
                  bind:value={item.val}
                  type="text"
                  class={item.isError ? "error-border" : ""}
                  placeholder={`${item.label}${item.required ? "*" : ""}`}
                  on:keypress={handleKeyPress}
                  on:keyup={(evt) => handleBlur(evt, i)}
                />
              </div>
            {:else if item.type == "file"}
              <div class="form-element">
                <p class="file-label">{item.label}</p>
                <input
                  bind:value={item.val}
                  type="file"
                  class={item.isError ? "error-border" : ""}
                  accept=".pdf, .docx, .xlsx, .jpg, .jpeg, .png, .zip"
                  on:change={(e) => handleFileSelected(e, i)}
                />
              </div>
            {:else if item.type == "number"}
              <div class="form-element">
                <input
                  bind:value={item.val}
                  type="number"
                  class={item.isError ? "error-border" : ""}
                  placeholder={`${item.label}${item.required ? " *" : ""}`}
                  on:keypress={handleKeyPress}
                  on:keyup={(evt) => handleBlur(evt, i)}
                />
              </div>
            {:else if item.type == "url"}
              <div class="form-element">
                <input
                  bind:value={item.val}
                  type="url"
                  class={item.isError ? "error-border" : ""}
                  placeholder={`${item.label}${item.required ? " *" : ""}`}
                  on:keypress={handleKeyPress}
                  on:keyup={(evt) => handleBlur(evt, i)}
                />
              </div>
            {:else if item.type == "date"}
              <div class="form-element">
                {#if isIPhone()}
                  {#if isDateInput[i]}
                    <input
                      class="form-element-input {item.isError ? 'error-border' : ''}"
                      bind:value={item.val}
                      type="date"
                      on:blur={() => onDateBlur(i)}
                      on:keypress={handleKeyPress}
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                  {:else}
                    <input
                      class="form-element-input {item.isError ? 'error-border' : ''}"
                      bind:value={item.val}
                      type="text"
                      on:focus={() => onDateFocus(i)}
                      placeholder={item.label}
                      on:keypress={handleKeyPress}
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                  {/if}
                {:else}
                  <input
                    class="form-element-input {item.isError ? 'error-border' : ''}"
                    bind:value={item.val}
                    type="text"
                    onfocus="(this.type='date')"
                    placeholder={`${item.label}${item.required ? " *" : ""}`}
                    on:keypress={handleKeyPress}
                    on:change={(evt) => handleBlur(evt, i)}
                  />
                {/if}
              </div>
            {:else if item.type == "time"}
              <div class="form-element">
                {#if isIPhone()}
                  {#if isTimeInput[i]}
                    <input
                      class="form-element-input {item.isError ? 'error-border' : ''}"
                      bind:value={item.val}
                      type="time"
                      on:blur={() => onTimeBlur(i)}
                      on:keypress={handleKeyPress}
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                  {:else}
                    <input
                      class="form-element-input {item.isError ? 'error-border' : ''}"
                      bind:value={item.val}
                      type="text"
                      on:focus={() => onTimeFocus(i)}
                      placeholder={item.label}
                      on:keypress={handleKeyPress}
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                  {/if}
                {:else}
                  <input
                    class="form-element-input {item.isError ? 'error-border' : ''}"
                    bind:value={item.val}
                    type="text"
                    onfocus="(this.type='time')"
                    placeholder={`${item.label}${item.required ? " *" : ""}`}
                    on:keypress={handleKeyPress}
                    on:change={(evt) => handleBlur(evt, i)}
                  />
                {/if}
              </div>
            {:else if item.type == "select"}
              {#if !item.showAsButton}
                <div class="form-element">
                  <div class="chatwith-select">
                    <!-- svelte-ignore a11y-no-onchange -->
                    <select bind:value={item.val} class={item.isError ? "error-border" : ""} on:change={(evt) => handleBlur(evt, i)}>
                      <option value="">--{item.label}--</option>
                      {#each toArray(item.data) as data}
                        <option value={data}>{getWrittenName(data, item?.dataObject)}</option>
                      {/each}
                    </select>
                  </div>
                </div>
              {:else}
                <div class="form-element">
                  <div class="chatwith-button-label">
                    {item.label}
                  </div>
                  <div class="chatwith-button-options">
                    {#each toArray(item.data) as data}
                      <button
                        type="button"
                        class="chatwith-option-button {item.val == data ? 'selected' : ''}"
                        on:click={(e) => selectOption(data, item, e.target)}
                      >
                        {getWrittenName(data, item?.dataObject)}
                      </button>
                    {/each}
                  </div>
                </div>
              {/if}
            {:else if item.type == "selectAndPrice"}
              {#if !item.showAsButton}
                <div class="form-element">
                  <div class="chatwith-select">
                    <!-- svelte-ignore a11y-no-onchange -->
                    <select bind:value={item.val} class={item.isError ? "error-border" : ""} on:change={(evt) => handleBlur(evt, i)}>
                      <option value="">--{item.label}--</option>
                      {#each toArray(item.data) as itemData}
                        <option value={itemData}>{getWrittenName(itemData, item?.dataObject, item?.showPrices, data?.currency)}</option>
                      {/each}
                    </select>
                  </div>
                </div>
              {:else}
                <div class="form-element">
                  <div class="chatwith-button-label">
                    {item.label}
                  </div>
                  <div class="chatwith-button-options">
                    {#each toArray(item.data) as itemData}
                      <button
                        type="button"
                        class="chatwith-option-button {item.val == data ? 'selected' : ''}"
                        on:click={(e) => selectOption(itemData, item, e.target)}
                      >
                        {getWrittenName(itemData, item?.dataObject, item?.showPrices, data?.currency)}
                      </button>
                    {/each}
                  </div>
                </div>
              {/if}
            {:else if item.type == "checkbox"}
              <div class="form-element">
                <div>
                  <label>
                    <input value={false} bind:checked={item.val} on:change={(evt) => handleBlur(evt, i)} type="checkbox" />
                    <p style="font-size: 12px">{item.label}</p>
                  </label>
                </div>
              </div>
            {:else if item.type == "hidden"}
              <div class="form-element">
                <span style="display: none">{item.label}</span>
                <input bind:value={item.value} type="hidden" />
              </div>
            {:else if item.type == "tel"}
              <div class="form-element">
                <TelField
                  {data}
                  bind:value={item.val}
                  bind:telNum={item.telNum}
                  bind:telCode={item.telCode}
                  placeholder={`${item.label}${item.required ? " *" : ""}`}
                  isError={item.isError}
                  on:message={(evt) => handleBlur(evt, i)}
                />
              </div>
            {:else if item.type == "email"}
              <div class="form-element">
                <input
                  bind:value={item.val}
                  type="email"
                  class={item.isError ? "error-border" : ""}
                  placeholder={`${item.label}${item.required ? " *" : ""}`}
                  on:keypress={handleKeyPress}
                  on:keyup={(evt) => handleBlur(evt, i)}
                />
              </div>
            {:else if item.type == "freetext" && item.hval}
              <div style="font-size: 13px !important;" class="form-element">
                {@html item.hval}
              </div>
            {/if}
            {#if !filtered[i]}
              <FormValidate
                bind:value={errorList}
                bind:this={formValidateList[i]}
                on:error={(val) => {
                  item.isError = val.detail;
                }}
                {item}
                {data}
                index={i}
              />
            {/if}
            {#if item.type == "checkbox" && item.legalUrl}
              <div class="checkbox-link">
                {#if isBigperson && item.legalCanUseLocalModal && cookieModalExist}
                  {@html `<a style="font-size: 12px" target="_blank" onClick="return modalPrivacityTermsCookies('privacityPopup');">${
                    item.legalUrlText ?? "Click here to see the Privacy Policy"
                  }</a
                >`}
                {:else}
                  <a style="font-size: 12px" target="_blank" href={item.legalUrl}>{item.legalUrlText ?? "Click here to see the Privacy Policy"}</a>
                {/if}
              </div>
            {/if}
          </div>
        {/each}
      {/if}
      {#if isSent}
        <Toast message={sendMessage} visible={isSent} />
      {/if}
      {#if formitems.buyLinks && formitems.buyLinks.length > 0 && !showBooking}
        {#each formitems.buyLinks as item, i}
          <Button
            id={"chatwith-buy-button" + i}
            on:click={() => sendtocallme(item.link)}
            buttonText={item.buttontext}
            showIcon={true}
            iconUrl={data.whatsappIconUrl}
            spanClassName={item.buttontext.length >= 25 ? "chatwith-button-long-span" : ""}
          />
        {/each}
      {:else}
        {#if isLoading}
          <Spinner />
        {/if}
        <Button
          on:click={() => sendtocallme(null)}
          buttonText={showBooking ? formitems.buttontext ?? "Book now" : formitems.buttontext}
          showIcon={true}
          iconUrl={data.whatsappIconUrl}
          spanClassName={formitems.buttontext.length >= 25 ? "chatwith-button-long-span" : ""}
          containerClassName={data.widgetType === "bigperson" ? "" : "fixed-bottom"}
        />
      {/if}
    </div>
  </div>
</div>
